<template>
  <span />
</template>
<script>
import gql from "graphql-tag";
import increaseReferralCodeUsageMutation from "@/graphql/mutations/increaseReferralCodeUsage.mutation.gql";

export default {
    data() {
        return {
            toHome: ["e", "a", "s"],
            toProfile: ["c", "p", "u"],
            code: "",
        };
    },
    computed: {
        referralType: (state) => state.$route.params.referralType,
        user: (state) => state.$route.params.user,
    },
    async mounted() {
        if (
            this.toHome.indexOf(this.referralType) >= 0 ||
            this.toProfile.indexOf(this.referralType) >= 0
        ) {
            this.getReferralCode();
        } else {
            await this.$router.push("/");
        }
    },
    methods: {
        async getReferralCode() {
            let query = gql`
                    query {
                      personGetReferralCode(uuid: "${this.$route.params.user}")
                    }
                `;
            await this.$apollo.query({ query }).then(async (r) => {
                let code = r.data.personGetReferralCode;
                await this.$apollo
                    .mutate({
                        mutation: gql`
                            ${increaseReferralCodeUsageMutation}
                        `,
                        variables: {
                            code: code,
                        },
                    })
                    .then((r) => {
                        console.log(r);
                    });
                this.$cookies.set("referralCode", code, "1d");
                if (this.toHome.indexOf(this.referralType) >= 0) {
                    this.$router.push("/");
                }
                if (this.toProfile.indexOf(this.referralType) >= 0) {
                    this.$router.push({
                        name: "public_person",
                        params: { uuid: this.user },
                    });
                }
            });
        },
    },
};
</script>
